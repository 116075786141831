html, body { min-height: 100vh; }
body { background-color: transparent!important; }
html {
    background-color: #3c4cad;
    background-image: linear-gradient(to bottom, #3c4cad, #c24ca2, #ff6d7d, #ffb05d, #f9f871);
    color: #6b7280;
}

.btn {
    display: block;
    color: #fff;
    border: 1px solid white;
    white-space: nowrap;
    text-align: center;
    margin: .5rem;
    padding: .5rem 1.5rem;
    border-radius: 0.25rem;
}

.btn:hover {
    background-color: white;
    color: inherit;
}